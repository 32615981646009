var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3e24aaec175b240626a7bc595db14dbb8428dbd2"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT
const enabledContexts = ['production', 'preview']

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://301536493d11451caacabdc321c154c7@o1161490.ingest.sentry.io/6248614',
  tracesSampleRate: 0.33,
  enabled: enabledContexts.includes(process.env.SENTRY_ENVIRONMENT),

  beforeSend: (event, hint) => {
    if (SENTRY_ENV !== 'production') {
      console.error(hint.originalException || hint.syntheticException)
    }
    //Disable sending event to sentry from localhost
    return SENTRY_ENV === 'development' ? null : event
  },
})
